@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	cursor: default;
}

:root {
	/* rest of the vars */
	--borderRadius: 4px;
	--transition: 0.3s ease-in-out all;
	/* Floating button */
	--floating-shadow: 0px 0px 1px rgba(10, 22, 70, 0.06),
		0px 6px 6px -1px rgba(10, 22, 70, 0.1);
}

.roundTop {
	background: -moz-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 4px,
			#fff 4px
		),
		-moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 4px, #fff 4px),
		-moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
	background: -o-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 0px,
			#fff 0px
		),
		-o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0px, #fff 0px),
		-o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
	background: -webkit-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 0px,
			#fff 0px
		),
		-webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0px, #fff 0px),
		-webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
}

.roundTop {
	background-position: bottom left, bottom right, top right, top left;
	-moz-background-size: 50% 50%;
	-webkit-background-size: 50% 50%;
	background-size: 50% 50%;
	background-repeat: no-repeat;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.roundBottom {
	background: -moz-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px),
		-moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px);
	background: -o-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px),
		-o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px);
	background: -webkit-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px),
		-webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0px, #fff 0px);
}

.roundBottom {
	background-position: bottom left, bottom right, top right, top left;
	-moz-background-size: 50% 50%;
	-webkit-background-size: 50% 50%;
	background-size: 50% 50%;
	background-repeat: no-repeat;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.roundAll {
	background: -moz-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
	background: -o-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
	background: -webkit-radial-gradient(
			0 100%,
			circle,
			rgba(204, 0, 0, 0) 10px,
			#fff 10px
		),
		-webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px),
		-webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 10px, #fff 10px);
}

.roundAll {
	background-position: bottom left, bottom right, top right, top left;
	-moz-background-size: 50% 50%;
	-webkit-background-size: 50% 50%;
	background-size: 50% 50%;
	background-repeat: no-repeat;
}

.cookiesLink {
	color: #000000de;
	font-weight: 700;
}

.iFrame-custom {
	border-width: 0;
	height: 600px;
}

@media (max-width: 770px) {
	.iFrame-custom {
		border-width: 0;
		height: 500px;
	}
}
